import React, { Fragment } from 'react'
import Link from 'gatsby-link'
import Showdown from 'showdown'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import TextImage from '../components/columnTextImageBelow'
import Related from '../components/related'
import Faqs from '../components/faqs'
import Cta from '../components/cta'
import Card from '../components/card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'

library.add(fat)

function parseLink(link, path) {
  link = path ? path.substring(0, path.substring(0, path.length-1).lastIndexOf("/")+1) + link.replace("../", "") + "/" : link
  return link
}

const ProductTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const heroImage = data.heroImage
  const path = location.pathname
  const converter = new Showdown.Converter()  
  return (
    <div id="sub-page">
      <Layout>
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
        />

        <Hero
          class="intro-65"
          image={heroImage?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}          
          type="root"
        />

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol lg={ post.frontmatter.headline && post.frontmatter.headline.length >= 1 ? '8' : '12' } >
              <div
                className="font-w-400 text-xs-extra-large divlink text-opening-para"
                dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.frontmatter.openingpara), }}
              />
                <div
                  className="richtext divlink mt-3"
                  dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.html) }}
                />
              </MDBCol>

              {post.frontmatter.headline &&
                post.frontmatter.headline.length >= 1 && (
                  <MDBCol lg="4" md="12" className="pl-lg-5">
                    {post.frontmatter.headline.map((headlines, index) => (
                      <div className="headline-tile mt-lg-3 item-headline" key={index} >
                        <div className="headline-icon">
                          <FontAwesomeIcon
                            icon={['fat', headlines.icon]}
                            size="2x"
                            className="fa-fw"
                          />
                        </div>
                        <div className="headline-content">
                          <h3 className="font-alt font-w-700 letter-spacing-1 text-xs-medium text-medium"> {headlines.title} </h3>
                          <div className="headline-action font-alt letter-spacing-1 text-xs-medium text-small">
                            {headlines.resource ? (
                              <a href={headlines.resource.publicURL} className="effect-noline" target="_blank" rel="noopener" > {headlines.linktext} <FontAwesomeIcon icon={faChevronRight} className="ml-2" /> </a>
                            ) : headlines.link.charAt(0) != '/' ? (
                              <a href={headlines.link} className="effect-noline" target="_blank" rel="noopener" > {headlines.linktext} <FontAwesomeIcon icon={faChevronRight} className="ml-2" /> </a>
                            ) : (
                              <Link to={headlines.link} className="effect-noline" > {headlines.linktext} <FontAwesomeIcon icon={faChevronRight} className="ml-2" /> </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </MDBCol>
                )}
            </MDBRow>
          </MDBContainer>
        </section>
          
        {post.frontmatter.section.map((sections, index) => (
          <section className="bg-light-blue-prods" key={index}>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="4" className="pr-5">
                  <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                    {sections.title}
                  </h2>
                  <p className="text-medium text-gray-dark mt-3">
                    {sections.description}
                  </p>
                </MDBCol>

                <MDBCol md="8">
                  <MDBRow>
                    {sections.feature.map((features, index) => (
                      <MDBCol md="6" className="pb-5 item-prods d-flex" key={index}>
                        <div className="card border-0 flex-fill card-body-transparent">
                        <div className="card-body pt-0">
                        <div className="feature-icon">
                          <FontAwesomeIcon
                            icon={['fat', features.icon]}
                            size="3x"
                          />
                        </div>
                        <p className="text-medium text-blue-dark font-w-700">
                          {features.title}
                        </p>
                        <p className="text-medium text-gray-dark">
                          {features.description}
                        </p>
                        </div>
                        {features.link !== null && (
                          <div className="card-footer border-top-0 mt-n4">
                              <p className="text-small">
                              {(features.link.substring(0, 4) != 'http' && features.link.substring(0, 8) != '/static/') ? (
                                <Link to={(features.link.indexOf('?') > -1 || features.link.indexOf('#') > -1 || features.link.slice(-1) == "/") ? features.link : '' + `${path}${features.link}/`} className="effect">
                                  {features.linktext}
                                </Link>
                              ) : (
                                <a href={features.link} className="effect" target="_blank" rel="noopener" > {features.linktext} </a>
                              )}
                              </p>
                          </div>
                        )}
                        </div>
                      </MDBCol>
                    ))}
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>
        ))}

        {(post.frontmatter.related && post.frontmatter.related.length > 0) && (  
          <section className="bg-gray-light">
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12" className="mb-5">
                  <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                    Related products
                  </h2>
                </MDBCol>
              </MDBRow>

              <MDBRow>
                {post.frontmatter.related.map((relate, index) => (
                  <Fragment key={index}>
                    {(relate.document || relate.link) &&
                      <Related
                        key={index}
                        collg="4"
                        colmd="6"
                        height="8rem"
                        type={relate.type}
                        title={relate.title}
                        subtitle={relate.subtitle}
                        description={relate.description}
                        link={ relate.document ? relate.document.publicURL : relate.link }
                      />
                    }
                  </Fragment>
                  )
                )}
              </MDBRow>
            </MDBContainer>
          </section>
        )}

        {post.frontmatter.capabilities != null &&
          post.frontmatter.capabilities.length > 0 && (
            <section className="bg-gray-light">
              <MDBContainer>
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5">
                  Other LibertySoft features
                </h2>
                <MDBRow>
                  {post.frontmatter.capabilities.map((addon, index) => (
                    <MDBCol key={index} lg="4" md="6" className="pb-card d-flex">
                      <div className="card flex-fill outline">
                        <div className="card-body card-hover">
                          <div className="font-alt font-w-700 letter-spacing-1 text-medium pb-2">
                            <Link to={parseLink(addon.link, location.pathname)} className="effect">{addon.linktext}</Link>
                          </div>
                          <div className="font-alt font-w-400 letter-spacing-1 text-medium-small">
                            {addon.description}
                          </div>                        
                        </div>
                      </div>
                    </MDBCol>
                  ))}
                </MDBRow>
              </MDBContainer>
            </section>
          )}       

        {post.frontmatter.helpfulresources && (
          <section className="bg-white-grey-gradient">
              <MDBContainer>
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left">
                  {' '}
                  {post.frontmatter.helpfulresources.title}{' '}
                </h2>

                {post.frontmatter.helpfulresources.helpfulres && (
                  <MDBRow className="pt-5">
                    <>
                      {post.frontmatter.helpfulresources.helpfulres.map(
                        (helpfulres) => {
                          return (
                            <Card
                              collg="4"
                              colmd="6"
                              height="9rem"
                              title={helpfulres.title}
                              subtitle={helpfulres.subtitle}
                              description={helpfulres.description}
                              image={ helpfulres.image.childImageSharp.gatsbyImageData }
                              alttext={helpfulres.alttext}
                              placement={helpfulres.placement}
                              link={ helpfulres.link ? helpfulres.link : helpfulres.document.publicURL }
                              titleclass="title-small"
                              descriptionClass="text-card-small py-2"
                            />
                          )
                        }
                      )}
                    </>
                  </MDBRow>
                )}
              </MDBContainer>
          </section>
        )}              

      {(post.frontmatter.faq && post.frontmatter.faq.length >= 1) && 
        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol md="12" className="pb-5">
                <h2 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-large">
                  Frequently asked questions (FAQ)
                </h2>
              </MDBCol>
            </MDBRow>

            {post.frontmatter.faq && <Faqs items={post.frontmatter.faq} />}
          </MDBContainer>
        </section>
      }            

        {post.frontmatter.cta && (
          <Cta
            ctatitle={post.frontmatter.cta.title}
            ctadescription={post.frontmatter.cta.description}
            ctalink={post.frontmatter.cta.link}
            ctalinktext={post.frontmatter.cta.linktext}
          />
        )}
      </Layout>
    </div>
  )
}
export default ProductTemplate

export const query = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark( fields: { slug: { eq: $slug } } frontmatter: { template: { eq: "products" } } ) {
      html
      frontmatter {
        meta {
          title
          description 
        }
        template
        title
        subtitle
        ctatext
        ctaslug        
        openingpara
        headline {
          title
          icon
          linktext
          link
          resource {
            publicURL
          }
        }
        section {
          title
          description
          feature {
            title
            icon
            description
            linktext
            link
          }
        }      
        related {
          type
          title
          subtitle
          description
          link
          document {
            publicURL
          }
        }
        capabilities {
          link
          linktext
          description
        }        
        faq {
          question
          answer
        }
        helpfulresources {
          title
          helpfulres {
            title
            subtitle
            description
            placement
            link
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            document {
              publicURL
            }
          }
        }         
        cta {
          title
          description
          linktext
          link
        }
      }
    }
    heroImage: file(name: { eq: "product-banner" }) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
  }
`
